

































import { loadLang } from '@/utils/langs';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SKFooter extends Vue {
  get languages() {
    return this.$store.state.langs;
  }

  get selectedLang() {
    return (
      this.languages.filter(
        (x: { _id: string }) => x._id === this.$store.state.selectedLang
      )[0] || { title: 'Select a language' }
    );
  }

  public langChanged(to: string[]) {
    if (to.length === 0) return;
    const lang = to[0];
    const id = this.languages.filter(
      (x: { title: string }) => x.title === lang
    )[0]._id;
    loadLang(id);
  }

  public select(id: string) {
    loadLang(id);
  }
}
